<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="用户名称">
          <el-input
            v-model="searchForm.staffName"
            size="small"
            placeholder="用户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色名称">
          <el-input
            v-model="searchForm.roleName"
            size="small"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      :total="total"
      title="用户角色"
    >
      <template slot="btns">
        <el-button
          type="success"
          size="small"
          @click="addFrom"
          icon="el-icon-plus"
          class="addBtn"
          >新增</el-button
        >
      </template>
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="staffName" label="用户名称" align="center">
      </el-table-column>
      <el-table-column prop="roleName" align="center" label="角色名称">
      </el-table-column>
      <el-table-column prop="staffRoleNote" align="center" label="备注">
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="添加时间">
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span @click="detailItem(scope.row)" class="detail">
            <i class="el-icon-edit"></i>
          </span>
          <span @click="deleteItem(scope.row)" class="detail delete">
            <i class="el-icon-delete"></i>
          </span>
        </template>
      </el-table-column>
    </custom-tables>

    <custom-dialog
      :visible.sync="showDialog"
      :title="dialogTitle"
      width="500px"
      @close="colseDialog"
      @submit="editsubmit"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        ref="staffRef"
        class="edit-form"
        :rules="rules"
      >
        <el-form-item label="用户姓名" prop="staffId">
          <el-select
            class="block-select"
            size="small"
            v-model="editForm.staffId"
            placeholder="请选择用户"
            filterable
          >
            <el-option
              v-for="item in staffList"
              :key="item.staffId"
              :label="item.staffName"
              :value="item.staffId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roleIds">
          <el-select
            class="block-select"
            size="small"
            multiple
            v-model="editForm.roleIds"
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="staffRoleNote">
          <el-input
            size="small"
            v-model="editForm.staffRoleNote"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import sysManage from "../../../api/modules/sysManage";

export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      editForm: {
        idCardNo: "",
        orgaId: "", //机构id，有企业选企业，有公司选公司
        projId: "",
        staffId: "",
        staffMobile: "",
        staffName: "",
        staffPic: "",
        staffSex: "",
        companyParentId: "",
        companyId: "",
        roleIds: [],
      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      dialogTitle: "",
      rules: {
        staffId: [
          { required: true, message: "请选择用户姓名", trigger: "change" },
        ],
        roleIds: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      roleList: [],
      staffRoleIdList: [],
      staffList: [],
    };
  },
  mounted() {
    this.getInfo();
    this.getBasicInfo();
  },
  methods: {
    getInfo() {
      sysManage.getStaffRoleListByPage(this.searchForm).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    getBasicInfo() {
      // 获取角色列表
      sysManage.getRoleList().then((res) => {
        this.roleList = res.data;
        console.log("resss", res);
      });
      // 获取管理人员
      sysManage.selectStaffList().then((res) => {
        this.staffList = res.data;
      });
    },
    search() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    addFrom() {
      this.editForm = {};
      this.dialogTitle = "新增用户角色";
      this.showDialog = true;
    },
    colseDialog() {
      this.showDialog = false;
      this.getInfo();
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      console.log("item", item);
      // 确保 roleIds 是一个数组，即使只有一个角色
      let roleIds = item.roleId ? [item.roleId] : [];
      this.editForm = {
        ...this.editForm, // 保留 editForm 的其他属性
        staffId: item.staffId,
        staffName: item.staffName,
        staffRoleNote: item.staffRoleNote,
        roleIds: roleIds, // 更新 roleIds 为数组
      };
      this.dialogTitle = "编辑用户角色";
      this.showDialog = true;
    },
    deleteItem(item) {
      console.log(item);
      this.$confirm(`此操作将永久删除该用户角色, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sysManage
            .deleteStaffrole({ staffRoleIds: [item.staffRoleId] })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getInfo();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editsubmit() {
      this.$refs.staffRef.validate((valid) => {
        if (valid) {
          sysManage.addStaffrole(this.editForm).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.getInfo();
            this.showDialog = false;
          });
        }
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getInfo();
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
.addBtn {
  background-color: #008f4d;
}
</style>
